import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DomainIcon from "mdi-material-ui/Domain";
import Home from "mdi-material-ui/Home";

import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import BuildIcon from '@material-ui/icons/Build';

import SessionStore from "../stores/SessionStore";
import localStore, { VIEW_ORGADMIN, VIEW_SYSADMIN, VIEW_USER } from "../stores/LocalStore";
import OrganizationStore from "../stores/OrganizationStore";
import ApplicationStore from "../stores/ApplicationStore";

import theme from "../theme";
import { Typography } from "@material-ui/core";
import AutocompleteSelect from "./AutocompleteSelect";
import AircomSystemStore from "../stores/AircomSystemStore";

const styles = {
  drawerPaper: {
    position: "fixed",
    width: 270,
    paddingTop: theme.spacing(9),
  },
  select: {
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: 0,
  },
};

class SideNav extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
      organization: null, // Organization controlled by top nav
      application: null,
      view: VIEW_USER,  // View controlled by top nav
      globalAdmin: false,
      cacheCounter: 0,
    };

    this.updateOrgAppFromLocation = this.updateOrgAppFromLocation.bind(this);
    this.getOrgIdFromLocation = this.getOrgIdFromLocation.bind(this);
    this.getAppIdFromLocation = this.getAppIdFromLocation.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.onAppSelectChange = this.onAppSelectChange.bind(this);
    this.getApplicationOption = this.getApplicationOption.bind(this);
    this.getApplicationOptions = this.getApplicationOptions.bind(this);
    this.onApplicationDeleted = this.onApplicationDeleted.bind(this);
  }

  componentDidMount() {
    // Triggered in following situations:
    // 1) User logs on. (sidenav not shown on login page)
    // 2) User refreshes page using Refresh / F5 button (url may or may not contain org id and app id)

    // If user not global admin, and has no organization, log out
    if (!SessionStore.isAdmin() && SessionStore.getOrganizationID() === null) {
      this.props.history.push("/login");
    }

    // Listeners not needed for org change as it will push change of url and trigger component update
    localStore.on("view.change", this.onViewChange);
    AircomSystemStore.on("application.deleted", this.onApplicationDeleted);

    this.updateOrgAppFromLocation();
    const globalAdmin = SessionStore.isAdmin();
    this.setState({globalAdmin, open: this.props.open});    
  }

  componentWillUnmount() {
    localStore.removeListener("view.change", this.onViewChange);
    AircomSystemStore.removeListener("application.deleted", this.onApplicationDeleted);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== this.state.open) {
      this.setState({open: this.props.open});
    }

    if (this.props === prevProps) {
      return;
    }

    // if (this.props.open !== prevProps.open) {
    //   this.setState({open: this.props.open});
    //   return;
    // }
    this.updateOrgAppFromLocation();
  }

  getOrgIdFromLocation() {
    const organizationRe = /\/organizations\/(\d+)/g;
    const match = organizationRe.exec(this.props.history.location.pathname);

    if (match !== null) {
      return match[1];
    }

    return 0;
  };

  getAppIdFromLocation() {
    const applicationRe = /\/applications\/(\d+)/g;
    const match = applicationRe.exec(this.props.history.location.pathname);

    if (match !== null) {
      return match[1];
    }

    return 0;
  };

  onViewChange() {
    const view = localStore.getView();
    this.setState({view});
  }

  onAppSelectChange(e) {
    const orgId = this.state.organization ? this.state.organization.id : 0;
    if (orgId > 0) {
      this.props.history.push(`/organizations/${orgId}/applications/${e.target.value}/dashboard`);
      // onComponentUpdate event will update state from location
    }
  }

  getApplicationOption(id, callbackFunc) {
    ApplicationStore.get(id, resp => {
      callbackFunc({label: resp.application.name, value: resp.application.id});
    });
  }

  getApplicationOptions(search, callbackFunc) {
    const orgId = this.state.organization ? this.state.organization.id : 0;
    ApplicationStore.list(search, orgId, 99, 0, resp => {
      const options = resp.result.map((o) => {return {label: o.name, value: o.id}});
      callbackFunc(options, resp.totalCount);
    });
  }

  onApplicationDeleted(id) {
    // Has current application been deleted?
    const {application} = this.state;
    if (application !== null && application.id === id) {
      localStore.setApplicationID("");
      const {cacheCounter} = this.state;
      this.setState({application: null, cacheCounter:cacheCounter + 1}, () => {
        this.props.history.push("/"); // Organization redirect will sort out next stop
      })
    }
  }

  updateOrgAppFromLocation() {
    // Need to pick up changes of org and app
    // DO NOT PUSH NEW ROUTE (Could conflict with TopNav)
    // DO NOT CALL SESSIONSTORE.SETORGANIZATIONID() (TopNav will do it)
    const newOrgID = this.getOrgIdFromLocation();
    const newAppID = this.getAppIdFromLocation();

    let {organization, application} = this.state;

    // Quick exit if no details changed
    if (organization !== null && organization.id === newOrgID && application !== null && application.id === newAppID) {
      return;
    }

    // Get applications first. We can't assume that location app id is valid
    // User may have typed invalid app id in browser address bar
    // If organization id is wrong, topnav will sort it
    
    ApplicationStore.list("", 0, 99, 0, resp => {
      let applications = resp.result;

      // Organization needs to be fetched
      if ((newOrgID > 0) && ((organization === null) || (organization.id !== newOrgID))) {
        organization = null;
        // Following line will not call callback is no result (but topnav will sort that and redirect)
        // User will see message "Object doesn't exist"
        OrganizationStore.get(newOrgID, resp => {
          organization = resp.organization;
          application = null;
          // Get application, if it exists and belongs to organiaation
          if (newAppID > 0) {
            applications = applications.filter(app => app.id === newAppID && app.organizationID === newOrgID);
            if (applications.length > 0) {
              application = applications[0];
            }
          }
          localStore.setApplicationID(application ? application.id : "");
          this.setState({organization, application});
        });
        return;
      }

      if ((newAppID > 0) && ((application === null) || (application.id !== newAppID))) {
        application = null;
        applications = applications.filter(app => app.id === newAppID && app.organizationID === newOrgID);
        if (applications.length > 0) {
          application = applications[0];
        }
        localStore.setApplicationID(newAppID);
        this.setState({organization, application});
      }
    });
  }

  render() {
    const {organization, application, view} = this.state;

    if (organization === null) {
      // User is on system but not global admin and no organization access
      // Don't exit if globalAdmin. All organizations could have been deleted!
      return null;
    }

    const orgAdminView = view === VIEW_ORGADMIN;
    const systemAdminView = view === VIEW_SYSADMIN;

    let organizationID = "";
    let organizationName = "";
    if (organization !== null) {
      organizationID = organization.id;
      organizationName = organization.name;
    }
    // let application = null;
    // if (organization !== null && this.state.application !== null) {
    //   application = this.state.application;
    // }
    // const showHomeItem = (application !== null) && (organization !== null);
    const applicationID = application ? application.id : null;

    return(
      <Drawer
        variant="temporary"
        anchor="left"
        open={this.state.open}
        onClose={() => {
          this.props.setDrawerOpen(false);
        }}
        classes={{paper: this.props.classes.drawerPaper}}
      >
        {/* {showHomeItem && (
          <List>
            <ListItem button divider component={Link} to={`/organizations/${organization.id}/applications/${application.id}/dashboard`}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Dashboard"/>
            </ListItem>
          </List>
        )} */}
        {systemAdminView && (
          <List onClick={() => this.props.setDrawerOpen(false)}>
            <ListItem button divider component={Link} to="/system">
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="System" />
            </ListItem>
          </List>
        )}

        {(orgAdminView || systemAdminView) && (
          <List onClick={() => this.props.setDrawerOpen(false)}>
            <ListItem button component={Link} divider disabled={!this.state.organization} to={`/organizations/${organizationID}`}>
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText primary="Organization Admin" secondary={organizationName}/>
            </ListItem>
          </List>
        )}

        {this.state.organization && (
        <div styles={{pointerEvents: 'none'}}>
          <Typography variant="h6" style={{paddingBottom: 0}} className={this.props.classes.select}>
            Application
          </Typography>
        <AutocompleteSelect
          id="applicationID"
          margin="none"
          value={applicationID}
          onChange={this.onAppSelectChange}
          getOption={this.getApplicationOption}
          getOptions={this.getApplicationOptions}
          className={this.props.classes.select}
          triggerReload={this.state.cacheCounter}
        />
      </div>

        )}

      {application && (
        <List onClick={() => this.props.setDrawerOpen(false)}>
          {(orgAdminView || systemAdminView) && (
            <ListItem button divider component={Link} to={`/organizations/${this.state.organization.id}/applications/${application.id}`}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Application Admin" secondary={application.name}/>
            </ListItem>
          )}
          <ListItem button divider component={Link} to={`/organizations/${organization.id}/applications/${application.id}/dashboard`}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
          </ListItem>
          <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/applications/${this.state.application.id}/uplinks`}>
            <ListItemIcon>
              <ArrowUpwardIcon />
            </ListItemIcon>
            <ListItemText primary="Uplinks" />
          </ListItem>
          <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/applications/${this.state.application.id}/downlinks`}>
            <ListItemIcon>
              <ArrowDownwardIcon />
            </ListItemIcon>
            <ListItemText primary="Downlinks" />
          </ListItem>
          <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/applications/${this.state.application.id}/device-groups`}>
            <ListItemIcon>
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Device Types" />
          </ListItem>
            <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/applications/${this.state.application.id}/devices`}>
            <ListItemIcon>
              <PhonelinkRingIcon />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
          {/* <ListItem button component={Link} to={`/organizations/${this.state.organization.id}/applications/${this.state.application.id}/events`}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </ListItem> */}
        
          </List>
      )}
      <Divider />
      <List onClick={() => this.props.setDrawerOpen(false)}>
        <ListItem button component={Link} to="/utils">
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Utils" />
        </ListItem>
      </List>
      </Drawer>
    );
  }
}

SideNav.propTypes = {
  classes: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(SideNav));
