import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import AppItemStore from "../../stores/AppItemStore";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import FormControl from "@material-ui/core/FormControl";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import Button from "@material-ui/core/Button";
import Paper from "../../components/Paper";

const styles = {
  card: {
    overflow: "visible",
  },
  formControl: {
    paddingTop: 24,
  },
};


class UpdateDeviceGroupMappings extends Component {
  constructor() {
    super();
    this.state = {
      itemsOptions: [],
    };
    this.getAppDeviceGroupMappingOption = this.getAppDeviceGroupMappingOption.bind(this);
    this.getAppDeviceGroupMappingOptions = this.getAppDeviceGroupMappingOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    AppItemStore.listPayloadItem(resp => {
      //get all the options - need to do this only once (up_payload_item does not change)
      var options = resp.result.map((ag) => {return {label: ag.name, value: ag.id}});
      options.push({label: "", value: 0})
      this.setState({
        itemsOptions: options,
      });
    });

    AppItemStore.getGroupItemMap(this.props.deviceGroup.id, resp => {
      this.setState({
        appItemMappings: resp.result,
      });
    });
  }

  getAppDeviceGroupMappingOption(id, callbackFunc) {
    const {itemsOptions} = this.state;

    var filtereditem = itemsOptions.filter(function(event){
      return event.value == id;
    });

    if (filtereditem.length > 0)
    {
      callbackFunc(filtereditem[0]);
    } else {
      callbackFunc({label: "", value: 0});
    }
  }

  getAppDeviceGroupMappingOptions(search, callbackFunc) {
    const {itemsOptions} = this.state;

    callbackFunc(itemsOptions);
  }

  onSubmit() {
    const {appItemMappings} = this.state;

    AppItemStore.updateGroupItemMap(this.props.deviceGroup.id, appItemMappings, () => {
      this.props.history.push(`/organizations/${this.props.application.application.organizationID}/applications/${this.props.application.application.id}/device-groups/${this.props.deviceGroup.id}`);
    });
  }

  onChange(obj) {
    let {appItemMappings} = this.state;
    appItemMappings[obj.target.id].upPayloadItemID = obj.target.value;
    this.setState({appItemMappings});
  }

  render() {
    let {appItemMappings} = this.state;
    if (!appItemMappings) {
      return <div></div>;
    }
    return(
      
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Paper >
         
              <Table className={this.props.classes.table} padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell>App Item</TableCell>
                    <TableCell>Source Upload Packet Item</TableCell>
                  </TableRow>                  
                </TableHead>
                <TableBody>
                  {this.state.appItemMappings.map((value, index) => {
                    return(
                      <TableRow>
                        <TableCell>{value.appItem.name}</TableCell>
                        <TableCell>
                          <FormControl fullWidth margin="none" disabled={this.props.disabled}>
                            <AutocompleteSelect
                                id={index}
                                label="Upload packet item"
                                value={appItemMappings[index].upPayloadItemID}
                                onChange={this.onChange}
                                getOption={this.getAppDeviceGroupMappingOption}
                                getOptions={this.getAppDeviceGroupMappingOptions}
                                helperText="Device Type"
                                disabled={this.props.disabled}
                                clearable
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>                  
                    );
                  })}
                </TableBody>
              </Table>
              <Grid container justify="flex-end" className={this.props.classes.formControl}>
                <Button color="primary" onClick={this.onSubmit} disabled={this.props.disabled}>Update group mappings</Button>
              </Grid>
          </Paper>
        </Grid>
      </Grid>
      
    );
  }
}

UpdateDeviceGroupMappings.propTypes = {
  application: PropTypes.object.isRequired,
  classes: PropTypes.any.isRequired,
  deviceGroup: PropTypes.object.isRequired,
  disabled: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(UpdateDeviceGroupMappings));
