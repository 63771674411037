import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import ApplicationStore from "../../stores/ApplicationStore";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import AircomOutput from "./AircomOutput"
import ApplicationDownlinksDetails from "./ApplicationDownlinksDetails";;


const styles = {
};

class ApplicationDownlinks extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
      aircomOutput: false,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    AppDeviceGroupStore.list(this.props.match.params.applicationID, 999, 0, resp => {
      let useOutputSchedule = false;

      resp.result.every((group) => {
        if (group.useOutputSchedule) {
          useOutputSchedule = true;
          return false;
        }
        return true;
      });

      this.setState({
        aircomOutput: useOutputSchedule,
      });
    });

    this.locationToTab();
  }

  
  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/output")) {
      tab = 1;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
      <TitleBar>
      <TitleBarTitle title={`Application Downlinks (${this.state.application.application.name})`} />
      </TitleBar>
      <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label="Pending Downlinks" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/downlinks`} />
            { this.state.aircomOutput &&
            <Tab label="New Output Schedule" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/output`} />
            }
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/downlinks`} render={props => <ApplicationDownlinksDetails application={this.state.application.application} aircomOutput={this.state.aircomOutput} {...props} />} />
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/output`} render={props => <AircomOutput application={this.state.application.application} {...props} />} />
        </Grid>
    </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(ApplicationDownlinks));
