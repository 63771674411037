import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import moment from "moment";

const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class SystemSettingsForm extends FormComponent {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
          <TextField
              id="maxRetentionTimeDays"
              label="Max retention time (days)"
              helperText="All older items will be deleted"
              margin="normal"
              value={this.state.object.maxRetentionTimeDays || 0}
              onChange={this.onChange}
              type="number"
              disabled={this.props.disabled}
              required
              fullWidth
          />          
          <TextField
              id="prunedAt"
              label="Last pruned at"
              margin="normal"
              value={this.state.object.prunedAt ? moment(this.state.object.prunedAt).format("lll") : ""}
              disabled
              fullWidth
          />          
          <TextField
              id="prunedItemCount"
              label="Pruned item count"
              margin="normal"
              value={this.state.object.prunedItemCount || 0}
              disabled
              fullWidth
          />          
          </div>
      </Form>
    );
  }
}

export default withStyles(styles)(SystemSettingsForm);
