import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler} from "./helpers";
import dispatcher from "../dispatcher";

class AppDeviceStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/appDevice.swagger.json", sessionStore.getClientOpts());
  }

  listDeviceWithQueue(applicationId, limit, offset, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.AppDeviceService.AppDeviceService_ListDeviceWithQueue({
        applicationId: applicationId,
        limit: limit,
        offset: offset,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  list(applicationID, appDeviceGroupID, limit, offset, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.AppDeviceService.AppDeviceService_List({
        applicationId: applicationID,
        appDeviceGroupId: appDeviceGroupID,
        limit: limit,
        offset: offset,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  get(devEUI, applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceService.AppDeviceService_Get({
        devEUI: devEUI,
        applicationID: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  update(appDevice, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceService.AppDeviceService_Update({
        "appDevice.id": appDevice.id,
        body: {
          appDevice: appDevice,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  pause(id, pause, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceService.AppDeviceService_Pause({
        id: id,
        body: {
          pause: pause,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify(pause?"paused":"started");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  disable(id, disable, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceService.AppDeviceService_Disable({
        id: id,
        body: {
          disable: disable,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify(disable?"disabled":"enabled");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  delete(devEUI, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceService.AppDeviceService_Delete({
        devEUI: devEUI,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "app device has been " + action,
      },
    });
  }

}

const appDeviceStore = new AppDeviceStore();
export default appDeviceStore;
